import { Component, OnInit,OnDestroy  } from '@angular/core';
import { LoaderService } from './service/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { MessagingService } from './service/messaging.service';
import { SettingService } from './service/setting.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'BlueportalFrontend';
  message;
  login = false;
  currentpath="";
  private _subs: Subscription;
  constructor(private messagingService: MessagingService,private location: Location,private settingService: SettingService,private loaderService: LoaderService,private router: Router) {
   
    router.events.subscribe((val) => {
      this.currentpath = location.path();
    });
    if(localStorage.getItem('valley_of_rockville_centre_token')) {
      this.login = true;
    }
    else{
      this.login = false;
    }
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  onActivate(event) {
    window.scroll(0,0);
  }

  get_recaptcha(){
    this.settingService.Getrecaptcha().subscribe(
      datares => {
        localStorage.setItem('valley_of_rockville_centre_recaptcha_site_key', datares.site_key);
    });
  }

  ngOnInit(): void {
    var that = this;
    this.get_recaptcha();
     this.messagingService.requestPermission()
     this.messagingService.receiveMessage()
     this.message = this.messagingService.currentMessage;
    //  this._subs = that.router.events.subscribe(event => {
    //     if (event instanceof NavigationEnd) {
    //       (<any>window).ga('set', 'page', event.urlAfterRedirects);
    //       (<any>window).ga('send', 'pageview');
    //     }
    //   });
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
