<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- {{ msg(message) }} -->
    <p [innerHTML]="getInnerHTMLValue(message)"></p> 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary theme_button btn-lg mr-1" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" class="btn btn-outline-secondary theme_button_outline btn-lg" (click)="decline()">{{ btnCancelText }}</button> 
  </div>