import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class KdkService {

  _getHeaders() {
    // let headers = new Headers();    
    // headers.append('Content-Type', 'x-www-form-urlencoded');
    // return headers;

    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllCategory() : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PointsActivitie/GetAllActivitie",headers);
  }

  GetMemberByUser(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/PointsMember/GetMemberByUser/"+obj,headers);
  }

  PostAddMember(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/PointsMember/PostAddMember", obj,headers);
  }

  PostAddMemberActivitie(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/PointsMember/PostAddMemberActivitie", obj,headers);
  }

}
