
<div class="modal-content">
  <div class="modal-header" style="border-bottom: unset;">
     <h4 class="modal-title"></h4>
     <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
       <span aria-hidden="true">&times;</span>
     </button>
   </div>
   <div class="modal-body">
      <div class="container-fluid">
        
<div class="container">    
  <div class="row">
    <div class="col-xs-12 col-md-12 mr-auto ml-auto">            
      <div class="panel panel-default">
        <form [formGroup]="customStripeForm" (ngSubmit)="pay(customStripeForm.value)">
          <div class="panel-body mt-0">
            <div class="text-center text-primary">
                  <!-- <img [src]="logo" alt=""> -->
                  <h2>{{portalName}}</h2>
            </div>
            <p><b>{{ message }}</b></p>
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="form-group">
                  <label>CARD NUMBER</label>
                  <div class="input-group">
                    <input type="text" formControlName="cardNumber" [textMask]="mask" class="form-control"  placeholder="Valid Card Number" id="cardNumber" name="cardNumber"  />
                  </div>
                  <div *ngIf="submitted && customStripeForm.controls.cardNumber.errors" class="text-danger">
                    <div *ngIf="customStripeForm.controls.cardNumber.errors.required">Card number is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-9 col-md-9">
                <div class="form-group">
                  <label><span class="hidden-xs">EXPIRATION</span> DATE</label>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <select formControlName="expMonth" class="form-control">
                          <option value="">Select Month</option>
                          <option value="1">01</option>
                          <option value="2">02</option>
                          <option value="3">03</option>
                          <option value="4">04</option>
                          <option value="5">05</option>
                          <option value="6">06</option>
                          <option value="7">07</option>
                          <option value="8">08</option>
                          <option value="9">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 pull-right">
                    <div class="form-group">
                      <select formControlName="expYear" class="form-control">
                        <option value="">Select Year</option>
                        <option *ngFor="let year of years" [value]="year">
                          {{year}}
                      </option>
                      </select>
                    </div>
                  </div>
                </div>
 
                <div *ngIf="submitted && customStripeForm.controls.expMonth.errors" class="text-danger">
                  <div *ngIf="customStripeForm.controls.expMonth.errors.required">Expiration month is required</div>
                </div>
 
                <div *ngIf="submitted && customStripeForm.controls.expYear.errors" class="text-danger">
                  <div *ngIf="customStripeForm.controls.expYear.errors.required">Expiration year is required</div>
                </div>
              </div>                            
            </div>
            <div class="col-xs-3 col-md-3 pull-right">
              <div class="form-group">
                <label>CV CODE</label>
                <input type="text" formControlName="cvv" class="form-control" placeholder="CVC" maxlength="4" />
              </div>
              <div *ngIf="submitted && customStripeForm.controls.cvv.errors" class="text-danger">
                <div *ngIf="customStripeForm.controls.cvv.errors.required">CVV is required</div>
                <div *ngIf="customStripeForm.controls.cvv.errors.minlength">CVV must be at least 3 characters</div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer">
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <button class="btn btn-secondary theme_button btn-lg btn-block" *ngIf="!formProcess">Process payment</button>
              <span class="btn btn-secondary theme_button btn-lg btn-block" *ngIf="formProcess">Please wait...</span>
            </div>
            
          </div>
        </div>
      </form>
      <!-- <p class="mt-2">Try it out using the test card number 4242 4242 4242 4242, a random three-digit CVC number and any expiration date in the future.</p> -->
        <p class="mt-5"></p>
    </div>
  </div>
</div>
</div>
      </div>
   </div>
   <script>
     

      $(document).ready(function() {
        $('.creditCardText').keyup(function() {
          var foo = $(this).val().split("-").join(""); // remove hyphens
          if (foo.length > 0) {
            foo = foo.match(new RegExp('.{1,4}', 'g')).join("-");
          }
          $(this).val(foo);
        });
      });

      
   </script>

