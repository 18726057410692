import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.css']
})
export class FailureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('valley_of_rockville_centre_session_id');
    localStorage.removeItem('valley_of_rockville_centre_session_type');
  }

}
