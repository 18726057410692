
<section >
    <div class="" style="text-align: center;">
        <div style="border-radius:80px; height:80px; width:80px; background: #F8FAF5; margin:0 auto;">
          <i class="checkmark">&#10004;</i>
        </div>
          <h1>Thank you for your response</h1> 
          <p>Valley Of Rockville Centre Scottish Rite, NMJ.</p><br/><br/>
          <button class="yellowBtn" style="padding: 8px;" [routerLink]="['/']">
            continue
        </button>
    </div>
    <br/><br/><br/><br/><br/><br/>
    <!-- <div class="container" style="height: 460px;">
          <h5>Thank you for your response.</h5>
        <h5>Babylon Lodge, NMJ.</h5>
    </div> -->

</section>

