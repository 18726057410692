import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  _getHeadersAutho() {
    let key = 'valley_of_rockville_centre_token';
    let myItem = localStorage.getItem(key);
    let httpHeaders = new HttpHeaders({
      'Authorization' : 'Bearer ' + myItem
      });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  SampleOutput(obj:any) : Observable<any> {
    return this.http.get("http://localhost:32768/api/Application/SampleOutput/"+obj,{responseType: 'json'});
  }

  _getHeaders() {
    let key = 'valley_of_rockville_centre_token';
    let myItem = localStorage.getItem(key);
   
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
      //httpHeaders['Authorization'] = 'Bearer ' + myItem;
      //httpHeaders.set("authorization", "Bearer " + myItem);

    let options = {
      headers: httpHeaders
    };
    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetEventCheckIn(eid: any,uid:any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetEventCheckIn/"+ eid +"?uid="+uid,headers);
  }

  ThreeUpComingEvent() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/Get3UpComingEvent",{responseType: 'json'});
  }

  GetOpenfamilyEvent() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetOpenfamilyEvent",{responseType: 'json'});
  }

  // GetHomePastEvent() : Observable<any> {
  //   return this.http.get(this.baseUrl+"api/Event/GetHomePastEvent",{responseType: 'json'});
  // }

  // GetHomePastThreeEvent() : Observable<any> {
  //   return this.http.get(this.baseUrl+"api/Event/GetHomePastThreeEvent",{responseType: 'json'});
  // }

  PostAddEventVideoStream(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventVideoStream", obj,headers);
  }

  PostUpdateEventVideoStream(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateEventVideoStream", obj,headers);
  }

  PostDelEventVideoStream(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostDelEventVideoStream", obj,headers);
  }

  PoseResponseEventAttened(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PoseResponseEventAttened", obj,headers);
  }

  PostAddEventLabel(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventLabel", obj,headers);
  }

  PostUpdateEventLabel(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateEventLabel", obj,headers);
  }

  DeleteEventLabel(obj: any): Observable<any> { 
    let headers = this._getHeaders(); 
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventLabel/"+ obj,headers);
  }

  // EventSearch(obj:any) : Observable<any> {
  //   const body = new HttpParams()
  //   .set('Prefix', obj);
  //   let headers = this._getHeaders();
  //   return this.http.post(this.baseUrl+"api/Event/PostEventSearch",body,headers);
  // }

  GetEventinfoSend(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetEventinfoSend/"+ obj);
  }

  GetComingEventAttendMemberlist(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetComingEventAttendMemberlist/"+ obj);
  }

  PostGalleryTitle(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostGalleryTitle", obj,headers);
  }

  PostRecipientSend(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostRecipientSend", obj,headers);
  }

  GetAll_Event(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetAll_Event/"+ obj);
  }

  // GetAllEvents(lodgeid:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
  //   let headers = this._getHeaders();
  //   return this.http.get(this.baseUrl+"api/Event/GetAllEvent/"+lodgeid+"?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  // }

  GetAllPastEvent(pageIndex:any, pageSizeSelected:any,_userid:any) : Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.get(this.baseUrl+"api/Event/GetAllPastEvent?userid="+_userid+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected + "&offset="+offset,headers);
  }
  TestApi(): Observable<any> {
    const body = new HttpParams()
    .set('name','krimesh');

    let key = 'valley_of_rockville_centre_token';
    let myItem = localStorage.getItem(key);
    
    let headers = new HttpHeaders();
    headers.append("Authorization", "Bearer " + myItem);
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Access-Control-Allow-Origin", "*");

    return this.http.post(this.baseUrl+"api/Event/PostTest",body,{headers: headers});
  }

  GetAllUpComingEvent(pageIndex:any, pageSizeSelected:any,_userid:any) : Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
   return this.http.get(this.baseUrl+"api/Event/GetAllUpComingEvent?userid="+_userid+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected + "&offset="+offset,headers);
  }

  GetAllEventToday(pageIndex:any, pageSizeSelected:any,_userid:any) : Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
   return this.http.get(this.baseUrl+"api/Event/GetAllUpToday?userid="+_userid+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected + "&offset="+offset,headers);
  }

  // GetEvent() : Observable<any> {
  //   return this.http.get(this.baseUrl+"api/Event/GetEvent",{responseType: 'json'});
  // }

  GetAllEvent(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetAllPastEvent/"+obj,{responseType: 'json'});
  }

  // GetLodgeAEvent(obj:any) : Observable<any> {
  //   return this.http.get(this.baseUrl+"api/Event/GetLodgeAEvent/"+obj,{responseType: 'json'});
  // }

  GetFetchSingleEvent(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetFetchSingleEvent/"+obj,{responseType: 'json'});
  }


  PostEventAttendSingle(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostEventAttendSingle", obj,headers);
  }

  PostAddEvent(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEvent", obj,headers);
  }

  //Update EventId User
  PostAdUpdatedEventIdUser(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAdUpdatedEventIdUser", obj,headers);
  }

  //Update Event
  PostUpdateEvent(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostUpdateEvent", obj,headers);
  }

  //My Get Event Gallery
  GetMyALLEventImage(obj:any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostMyALLEventImage",obj,headers);
  }

  //My Get All Event Image
  // GetMyALLEventGallery(obj:any) : Observable<any> {
  //   let headers = this._getHeaders();
  //   return this.http.post(this.baseUrl+"api/Event/PostMyALLEventGallery",obj,headers);
  // }

  //Get Event Gallery
  GetEventGallery(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetEventGallery/"+obj,{responseType: 'json'});
  }

  //Get All Event Image
  GetAllEventImage(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetAllEventImage",{responseType: 'json'});
  }

  DeleteEvent(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEvent", options);
  }

  DeleteEventGallery(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventGallery", options);
  }

  DeleteEventDocument(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventDocument", options);
  }

  GetUserEventCheckIn(eventid:any,userid:any) : Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
   return this.http.get(this.baseUrl+"api/Event/GetUserEventCheckIn?EventId=" + eventid + "&UserId=" + userid + "&offset="+offset,headers);
  }

  //Get GetAllEventAttendlist 
  GetAllEventAttendlist(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetAllEventAttendlist/"+obj,{responseType: 'json'});
  }

  //Delete Event Attend
  DeleteEventAttened(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Event/DeleteEventAttened", options);
  }
  GetSingalEventAttend(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetSingalEventAttend/"+obj,{responseType: 'json'});
  }

  PostAddEventAttened(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Event/PostAddEventAttenedDes", obj,headers);
  }

  PutEventAttened(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Event/PutEventAttened", obj,headers);
  }

  GetImageRotate(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetImageRotate/"+obj,{responseType: 'json'});
  }

  GetImageRotateBack(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/GetImageRotateBack/"+obj,{responseType: 'json'});
  }

  Getuser(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Event/Getuser/"+ obj,{responseType: 'json'});
  }

  GetMailSendEvent(obj: any,userid: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Event/GetEventinfoSendWithIcs/"+ obj+"?userid="+userid,headers);
  }


}
