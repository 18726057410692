import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  AdvPayment() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Payment/AdvPayment",{responseType: 'json'});
  }

  GetCustomerAllcharges(obj: any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Payment/GetCustomerAllcharges/"+obj);
  }

  GetAllcharges() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Payment/GetAllcharges",{responseType: 'json'});
  }

  subscription(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Payment/subscription", obj,headers);
  }

  Charge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/Charge?offset=" + offset, obj,headers);
  }

  ChargePhilanthropy(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargePhilanthropy?offset=" + offset, obj,headers);
  }

  ChargeCashRegister(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargeCashRegister?offset=" + offset, obj,headers);
  }

  ChargeAdvPayment(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargeAdvPayment?offset=" + offset, obj,headers);
  }

  ChargePayDuePayment(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargePayDuePayment?offset=" + offset, obj,headers);
  }

  ChargePayForDinner(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargePayForDinner?offset=" + offset, obj,headers);
  }

  GetAllCards(obj: any) : Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Payment/ListCardinfo",obj,headers);
  }

  UpdateCardInformation(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Payment/UpdateCardInformation", obj,headers);
  }

  UpdateCard(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Payment/UpdateCard", obj,headers);
  }

  AddCardinfo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Payment/AddCardinfo", obj,headers);
  }

  CreateCheckoutSession() : Observable<any> {
    return this.http.get(this.baseUrl+"api/values/CreateCheckoutSession",{responseType: 'json'});
  }

  CreateCheckoutSessionNew(type:any,amount:any,desc:any,email:any) : Observable<any> {
    var _cancelurl = window.location.href;
    return this.http.get(this.baseUrl+"api/Payment/CreateCheckoutSession?type="+type+"&amount="+amount+"&desc="+desc+"&email="+email+"&cancelurl="+_cancelurl,{responseType: 'json'});
  }

  GetCheckoutSession(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Payment/GetCheckoutSession?id="+id,{responseType: 'json'});
  }

  ChargeDefault(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargeDefault?offset=" + offset, obj,headers);
  }

  ChargePhilanthropyDefault(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargePhilanthropyDefault?offset=" + offset, obj,headers);
  }

  ChargeCashRegisterDefault(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargeCashRegisterDefault?offset=" + offset, obj,headers);
  }

  ChargePayDuePaymentDefault(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargePayDuePaymentDefault?offset=" + offset, obj,headers);
  }

  ChargePayForDinnerDefault(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/Payment/ChargePayForDinnerDefault?offset=" + offset, obj,headers);
  }

}
