import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  
  _getHeadersAutho() {
    let key = 'valley_of_rockville_centre_token';
    let myItem = localStorage.getItem(key);
    let httpHeaders = new HttpHeaders({
      'Authorization' : 'Bearer ' + myItem
      });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  _getHeaders() {
    let key = 'valley_of_rockville_centre_token';
    let myItem = localStorage.getItem(key);
   
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
      //httpHeaders['Authorization'] = 'Bearer ' + myItem;
      //httpHeaders.set("authorization", "Bearer " + myItem);

    let options = {
      headers: httpHeaders
    };
    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  //Listname   Start
  GetAllListname(): Observable<any> {
    return this.http.get(this.baseUrl+"api/Email/GetAllListname");
  }

  GetSingleListname(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Email/GetSingleListname/"+ obj);
  }

  PostListname(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Email/PostListname", obj,headers);
  }

  PutListname(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Email/PutListname", obj,headers);
  }

  DeleteListname(obj: any): Observable<any> {  
    return this.http.delete(this.baseUrl+"api/Email/DeleteListname/"+ obj);
  }
   //Listname   End

   //ContactMaster   Start

   GetSingleContact(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Email/GetSingleContact/"+ obj);
  }

  PostContact(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Email/PostContact", obj,headers);
  }

  PutContact(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Email/PutContact", obj,headers);
  }

  DeleteContact(obj: any): Observable<any> {  
    return this.http.delete(this.baseUrl+"api/Email/DeleteContact/"+ obj);
  }

   //ContactMaster   End

   //Campaign Master  Start

   GetCampaign(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Email/GetCampaign/"+ obj);
  }

  PostCampaignSend(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Email/PostCampaignSend", obj);
  }

  PostCampaignSendm(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Email/PostCampaignSendm", obj);
  }

  POSTCampaign(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Email/POSTCampaign", obj,headers);
  }

  PUTCampaign(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Email/PUTCampaign", obj,headers);
  }

  DeleteCampaign(obj: any): Observable<any> {  
    return this.http.delete(this.baseUrl+"api/Email/DeleteCampaign/"+ obj);
  }

  DeleteCampaignFlyer(obj: any): Observable<any> {  
    return this.http.delete(this.baseUrl+"api/Email/DeleteCampaignFlyer/"+ obj);
  }
  ArchiveCampaign(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Email/GetArchiveCampaign/"+ obj);
  }
  PostMoveCampaign(obj: any): Observable<any> {
    return this.http.get(this.baseUrl+"api/Email/GetMoveCampaign/"+ obj);
  }
    //Campaign Master  End

    //Campaign button  Start

    GetCampaignButtoLink(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetCampaignButtoLink/"+ obj);
    }
  
    PostCampaignButtoLink(obj: any): Observable<any> {
      let headers = this._getHeaders();
      return this.http.post(this.baseUrl+"api/Email/PostCampaignButtoLink", obj,headers);
    }
  
    DeleteCampaignButtoLink(obj: any): Observable<any> {  
      return this.http.delete(this.baseUrl+"api/Email/DeleteCampaignButtoLink"+ obj);
    }

    //Campaign button  End
    //statu start
    GetStats(): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetStats");
    }

    GetTagStats(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetTagStats/"+ obj);
    }
    //statu end

    //tags start
    GetTags(): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetTags");
    }

    GetTag(obj: any): Observable<any> {
      let headers = this._getHeaders();
      return this.http.post(this.baseUrl+"api/MailGun/PostGetTag", obj,headers);
    }
  
    PutTag(obj: any): Observable<any> {
      let headers = this._getHeaders();
      return this.http.put(this.baseUrl+"api/MailGun/PutTag", obj,headers);
    }
  
    DeleteTag(obj: any): Observable<any> {  
      return this.http.delete(this.baseUrl+"api/MailGun/DeleteTag/"+ obj);
    }
  
    GetLogEntry(): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetLogEntry");
    }
    GetLog(obj: any): Observable<any> {
      let headers = this._getHeaders();
      return this.http.post(this.baseUrl+"api/MailGun/PostLogEntryByTag", obj,headers);
    }
    GetEventsTagStats(tag: any,type: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetEventsTagStats/"+tag+"?type="+type);
    }

    GetEventsTagStatsEmail(tag: any,type: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetEventsTagStatsEmail/"+tag+"?type="+type);
    }

    GetEventsTagStatsEmailOnlyMail(tag: any,type: any,timezone: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/MailGun/GetEventsTagStatsEmailOnlyMail/"+tag+"?type="+type+"&timezone="+timezone);
    }
    //tags end

    GetAllTemplate(): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetAllTemplate");
    }

    GetTemplate(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetTemplate/"+ obj);
    }

    GetEmailResponse(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetEmailResponse/"+ obj);
    }

    GetEmailTopResponse(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetEmailTopResponse/"+ obj);
    }

    GetTimeZone(): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetTimeZone");
    }

    POSTStatue(obj: any): Observable<any> {
      let headers = this._getHeaders();
      return this.http.post(this.baseUrl+"api/Email/POSTStatue", obj,headers);
    }

    GetNextUrl(obj: any): Observable<any> { 
      let headers = this._getHeaders();  
      return this.http.post(this.baseUrl+"api/MailGun/PostEmailStatus", obj,headers);
    }

    GetCampaignHistory(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetCampaignHistory/"+ obj);
    }

    GetCopyCampaign(obj: any): Observable<any> {
      return this.http.get(this.baseUrl+"api/Email/GetCopyCampaign/"+ obj);
    }
}
