import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams, HttpRequest, HttpEvent  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  uploadFileToUrl(t: any,n:any): Observable<any> {
    let headers = this._getHeaders();
    const formData = new FormData();
    formData.append('file', t);
    
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  uploadFileWithGallery(t: any,e:any,i:any,up:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t);
      formData.append('EventId',e);
      formData.append('UserId',i);
      formData.append('UploadType',up);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  
  uploadFileWithUserIdToUrl(t: any,n:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t);
      formData.append('userid',n);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  
  uploadEventDocumentWithEventId(t: any,n:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t);
      formData.append('eventid',n);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  
  uploadUploadValleyScottishRite(t: any,n:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+n,formData,headers);
  }

  
  uploadLodgeWithLodgeId(t: any,n:any,r:any): Observable<any> {
    const formData = new FormData();
      formData.append('file', t);
      formData.append('lodgeid',n);
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/"+r,formData,headers);
  }

  UploadLecture(obj: any,objParam: any): Observable<any> {
     const formData = new FormData();
     formData.append('file',obj);
     let headers = this._getHeaders();
     //return this.http.post(this.baseUrl+"api/Lecture/UploadLectureVideo",formData);
     return this.http.post(this.baseUrl+"app/FileUpload/UploadLecture"+objParam,formData,headers);
  }

}
