import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LectureService {
  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllLecture() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lecture/GetAllLecture",{responseType: 'json'});
  }
  GetAllLectureByMe(id:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lecture/GetAllLectureByMe?id="+id+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected,{responseType: 'json'});
  }
  GetAllLectureByOther(id:any,pageIndex:any, pageSizeSelected:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lecture/GetAllLectureByOther?id="+id+"&page=" + pageIndex + "&pageSize=" + pageSizeSelected,{responseType: 'json'});
  }

  GeLecture(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lecture/GetLecture/"+id,{responseType: 'json'});
  }

  GetLectureVideo(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lecture/GetLectureVideo/"+id,{responseType: 'json'});
  }

  GetLectureAllVideo(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lecture/GetLectureAllVideo/"+id,{responseType: 'json'});
  }


  PostAddLecture(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lecture/PostAddLecture", obj,headers);
  }

  PutUpdateLecture(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Lecture/PutUpdateLecture", obj,headers);
  }

  DeleteLecture(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Lecture/DeleteLecture", options);
  }


  PostDelVideo(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.post(this.baseUrl+"api/Lecture/PostDelVideo", obj,options);
  }
  DeleteLectureImage(obj: any): Observable<any> {  
    return this.http.delete(this.baseUrl+"api/Lecture/DeleteLectureImage", obj);
  }

  GetSubscriptionByUser(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/LectureSubscription/GetSubscriptionByUser/"+id,{responseType: 'json'});
  }

  PostAddSubscription(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/LectureSubscription/PostAddSubscription", obj,headers);
  }

  PostAddUpdateSubscription(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/LectureSubscription/PostAddUpdateSubscription", obj,headers);
  }

}
