import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SettingService {


  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetGraph() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetGraph",{responseType: 'json'});
  }

  GetSetting(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetSetting/" + obj,{responseType: 'json'});
  }

  GetPaymentall(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentall/" + obj,{responseType: 'json'});
  }

  GetPaymentDONATE(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentDONATE/" + obj,{responseType: 'json'});
  }

  GetCashRegister(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetCashRegister/" + obj,{responseType: 'json'});
  }

  GetReceipt(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetReceipt/" + obj,{responseType: 'json'});
  }

  GetPaymentinfo(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentinfo/" + obj,{responseType: 'json'});
  }

  GetPaymentPending(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetPaymentPending/" + obj,{responseType: 'json'});
  }

  GetPlan() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetPlan",{responseType: 'json'});
  }

  GetPlanPrice() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetPlanPrice",{responseType: 'json'});
  }

  PostPaymentCheckinfo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Setting/PostPaymentCheckinfo", obj,headers);
  }

  PostAddSetting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Setting/PostAddSetting", obj,headers);
  }

  //Delete UpdateCardInformation
  PutRemoveCardinfo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutRemoveCardinfo", obj,headers);
  }

  //Update UpdateCardInformation
  UpdateCardInformation(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateCardinfo", obj,headers);
  }

  //Update UpdateCardInformation
  PutUpdateSetting(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateSetting", obj,headers);
  }

  GetCustomerBillingInfo(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetCustomerBillingInfo/" + obj,{responseType: 'json'});
  }

  PutUpdateBilinginfo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutUpdateBilinginfo", obj,headers);
  }

  InsertUpdateManuallyPaymentSaveMaster(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/InsertUpdateManuallyPaymentSaveMaster", obj,headers);
  }

  GetlistUser() : Observable<any> {
    return this.http.get(this.baseUrl+"api/User/GetlistUser",{responseType: 'json'});
  }

  GetlistUserContect() : Observable<any> {
    return this.http.get(this.baseUrl+"api/User/GetlistUserContect",{responseType: 'json'});
  }

  GetAllPayNowWithEvent() : Observable<any> {
    return this.http.get(this.baseUrl+"api/PayNowMaster/GetAllPayNowWithEvent",{responseType: 'json'});
  }

  GetSocialMediaSetting() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetSocialMediaSetting",{responseType: 'json'});
  }

  Getrecaptcha() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/Getrecaptcha",{responseType: 'json'});
  }

}
