
<div class="modal-content">
     <div class="modal-body">
        <div class="container-fluid">
          <div class="searchBlock">
            <div class="inputRow" style="border: 1px solid #c3c3c382;">
              <input type="text" #input class="input" (keyup)="search($event.target.value)" placeholder="Search here..." id="searchtextfooter" name="searchtextfooter" >
              <img class="searchBtn" src="/assets/images/search_icon.png" >
             </div>
          </div>
          <div class="row col-lg-12" *ngFor="let item of data; let i = index;">
              <hr style="width:100%;" *ngIf="i > 0" />
              <div class="img_search_middle">
                <img src="/assets/images/akar-icons_arrow-right.png">
                <img [src]="image_blank(item.image)" onError="this.src='assets/images/lec-01.jpg'" class="rounded-circle ml-2 mr-2" style="width: 42px;height: 42px;">
                <div>
                  <h5><a href="javascript:void(0)" (click)="selectedItem(item)">{{item.name}}</a></h5>
                  <p>{{resultFormat(item.type)}}</p>
                </div>
                

              </div>
             
          </div>
        </div>
     </div>
    