import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllBusDir(pageIndex:any, pageSizeSelected:any) : Observable<any> {
    let headers = this._getHeaders();
   return this.http.get(this.baseUrl+"api/UserSet/GetAllBusDir?page=" + pageIndex + "&pageSize=" + pageSizeSelected,headers);
  }

}
