import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpParams  } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ToasterMsgService} from '../../service/toaster.service';
import {AccountService} from '../../service/account.service';
import {EmailService} from '../../service/email.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-emailres',
  templateUrl: './emailres.component.html',
  styleUrls: ['./emailres.component.css']
})
export class EmailresComponent implements OnInit {
  name = '';
  tag = '';
  constructor(
    private titleService: Title,
    private emailService: EmailService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ToasterService: ToasterMsgService) {
    this.titleService.setTitle('Email Response | Valley Of Rockville Centre');
  }

  call_func(){
    const offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.emailService.GetEventsTagStatsEmailOnlyMail(this.tag, 'clicked', offset).subscribe(
        x => {

        });
  }
  ngOnInit(): void {
    var that = this;
    this.activatedRoute.queryParams.subscribe(params => {
      this.tag = params.tg;
      setTimeout(function(){
        that.call_func();
      }, 2000);
    });
   
  }

}
