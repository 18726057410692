

<footer class="footer">
	<div class="container">
		<div class="row no-gutters align-items-center">
			<div class="col colB">
				<div class="searchBlock" *ngIf="login">

					<ng-template #rt let-r="result" let-t="term">
						<img [src]="image_blank(r.image)" onError="this.src='assets/images/lec-01.jpg'" class="rounded-circle mr-2" style="width: 42px;height: 42px;">
						<ngb-highlight [result]="resultFormat(r)" [term]="t"></ngb-highlight>
					  </ng-template>

					<div class="inputRow">
						<input type="text" #input placeholder="Search here..." id="searchtextfooter" name="searchtextfooter" [(ngModel)]="searchtext" (keyup)="SearchClick($event)" (selectItem)="selectedItem($event,input)"  name="searchtop" [ngbTypeahead]="search" [inputFormatter]="inputFormat" [resultTemplate]="rt" #instance="ngbTypeahead">
						<a href="javascript:void(0);" (click)="UserSearch()"><img class="searchBtn" src="/assets/images/search_icon.png" ></a>
						<!-- <button class="searchBtn" type="submit" (click)="UserSearch()"></button> -->
					</div>
				</div>
				<div class="footerLink">
						<a [routerLink]="['../home']">HOME</a>
						<a [routerLink]="['../about']">ABOUT US</a>
						<a [routerLink]="['../privacypolicy']">PRIVACY POLICY</a>
						<a [routerLink]="['../termsofuse']">TERMS OF USE</a>
						<a [routerLink]="['../contactus']">CONTACT US</a>
				</div>
				<!-- <div class="copyright">Copyright &copy; 2021 Valley Of Rockville Centre. All rights reserved.&nbsp; <span class="textA"> Powered By <a target="_blank" href="https://olympusweb.io/">Olympus</a></span> </div> -->
				<div class="copyright" style="font-size: 12px;">
					<span class="white_font footer_address mb-3">
						<a class="footer-font" href="mailto:{{valley_emails}}">
						<i class="fa fa-envelope text-white" aria-hidden="true"></i>&nbsp;{{valley_emails}}
				      </a>
					</span>&nbsp;&nbsp;
					<span class="white_font footer_address mb-3">
						<a class="footer-font" href="tel:{{valley_phone}}">
						<i class="fa fa-phone text-white" aria-hidden="true"></i>&nbsp;{{valley_phone}}
						</a>
					</span>&nbsp;&nbsp;
					<span class="white_font footer_address mb-3">
						<a class="footer-font" href="https://goo.gl/maps/W7mEXXtn2htFZs6T9">
						<i class="fa fa-map-marker text-white" aria-hidden="true"></i>&nbsp;{{valley_address}}
						</a>
					</span>
				</div>
				<div class="row col-md-12 mt-3">
					<div class="col-md-3 social-network-cen mb-3">
						<a class="mr-2" *ngIf="check_null(LinkedIn)" href="{{LinkedIn}}" target="_blank"><img class="" width="32" height="32" src="/assets/images/in_white.png"></a>
						<a class="mr-2" *ngIf="check_null(Twitter)" href="{{Twitter}}" target="_blank"><img class="" width="32" height="32" src="/assets/images/twitter_white.png"></a>
						<a class="mr-2" *ngIf="check_null(Facebook)" href="{{Facebook}}" target="_blank"><img class="" width="32" height="32" src="/assets/images/facebook_white.png"></a>
						<a class="mr-2" *ngIf="check_null(Instagram)" href="{{Instagram}}" target="_blank"><img class="" width="32" height="32" src="/assets/images/instagram_white.png"></a>
				
					</div>
					<div class="col-md-6 copyright">
						Copyright &copy; {{this._year}} {{portalName}}. All rights reserved.
					</div>
					<div class="col-md-3 powerby-cen">
						<div class="copyright"> <span class="textA" > <a target="_blank" href="https://olympusweb.io/"><img height="30" src="assets/images/white_logo.png"></a> </span> </div>
					</div>
				</div>
				
			</div>
			
			
			<!-- <div class="col colA">
        <div class="logo">
          <a [routerLink]="['../home']">
            <img [src]="logo" alt="">
          </a>
        </div>
			</div> -->
			
		</div>
	</div>
</footer>
