
<div class="" style="text-align: center;">
    <div style="border-radius:80px; height:80px; width:80px; background: #F8FAF5; margin:0 auto;padding-top: 30px;">
      <!-- <i class="checkmark">&#10006;</i> -->
      <img src="assets/images/close.png" width="30">
    </div>
      <h1>Cancel!</h1> 
      <p>Your Payment was not successful. Please try again.</p><br/><br/>
      <button class="yellowBtn" style="padding: 8px;" [routerLink]="['/']">
        try again
    </button>
</div>
<br/><br/><br/><br/><br/><br/>