import {Component, Input, OnInit} from '@angular/core';
import {MonthCard} from "../../interfaces/month-card";
import {UtilsService} from "../../utils/utils.service";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  @Input() numberOfMonths: number = 4;

  constructor(private utils: UtilsService) { }

  months: MonthCard[] = [];

  ngOnInit(): void {
    let date = new Date();
    this.months.push({text: "today", amount: this.utils.formatCurrency(41.79, true)} as MonthCard);
    for (let month = 1; month < this.numberOfMonths; month++) {
      this.months.push({text: this.utils.getFutureDate(date, month), amount: this.utils.formatCurrency(41.79, true)} as MonthCard);
    }
  }

}
