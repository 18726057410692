import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import { map} from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LodgeService {

  
  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;
  private extractData(res: Response) {
    let body = res.json();
    return body || { };
  }

  PostLodgeCheckDuplicate(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lodge/PostLodgeCheckDuplicate", obj,headers);
  }

  LodgeSearch(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"app/LodgePage/PostLodgeSearch", obj,headers);
  }

  

  GetAccessLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lodge/PostAccessLodge", obj,headers);
  }

  PostAddLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lodge/PostAddLodge", obj,headers);
  }

  PostAddGrandLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lodge/PostAddGrandLodge", obj,headers);
  }

  PutUpdateLodge(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Lodge/PutUpdateLodge", obj,headers);
  }

  GetLodgebasicinfo(obj: any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lodge/GetLodgebasicinfo/"+obj);
  }

  GetSingleLodge() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lodge/GetSingleLodge");
  }

  GetlistLodge() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lodge/GetlistLodge",{responseType: 'json'});
  }

  GetAllGrandLodge() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lodge/GetAllGrandLodge",{responseType: 'json'});
  }

  GetSingleScottishRite() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Setting/GetAppSetting",{responseType: 'json'});
  }

  GetAllLodgeEditor() : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lodge/GetAllLodgeEditor",{responseType: 'json'});
  }

  GetSingalLodgeEditor(obj: any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Lodge/GetSingalLodgeEditor/"+obj);
  }

  DeleteLodgeEditor(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Lodge/DeleteLodgeEditor", options);
  }

  DeleteLodge(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Lodge/DeleteLodge", options);
  }

  PostAddLodgeEditor(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Lodge/PostAddLodgeEditor", obj,headers);
  }
  PutUpdateLodgeEditor(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Lodge/PutUpdateLodgeEditor", obj,headers);
  }

  Deletewhatsnew(obj: any): Observable<any> {
    return this.http.delete(this.baseUrl+"api/whatnew/Deletewhatsnew", obj);
  }

  InsertUpdateValleyScottishRite(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Setting/InsertUpdateValleyScottishRite", obj,headers);
  }
  
  //Update DiscScottishRite
  DiscScottishRite(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Setting/PutDiscScottishRite", obj,headers);
  }

}
