<header class="header" >
	<div class="container">
		<div class="row align-items-center">
			<div class="col-auto">
				<div class="logo">
					<a [routerLink]="['../home']">
						<img [src]="logo" alt="">
					</a>
				</div>
			</div>
			<div class="col-auto col-md navCol text-right">
				<!-- <div class="mainNav">
					<button class="mobileNavBtn">
						<span></span>
						<span></span>
						<span></span>
					</button>
					<div class="overLay"></div>
					<nav class="navigation navigationWithoutLogin" style="float: right;">
						<ul>
							
							<li class="mr-0 center-display"><button class="btn btn-outline-secondary theme_button_outline btn-lg mr-1" (click)="hidemenu()" [routerLink]="['../account/login']">SIGN IN</button></li>
							
						</ul>
					</nav>
				</div> -->
				<a class="petition-now" style="padding-right: 19px;" href="https://scottishritenmj.org/membership-application">PETITION NOW</a>
				<button class="btn btn-outline-secondary theme_button_outline btn-lg mr-1" (click)="hidemenu()" [routerLink]="['../account/login']">LOGIN</button>
			</div>
			<div class="col col-md-auto">
				
			</div>
		</div>
	</div>
</header>
