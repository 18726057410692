<section >
    <div class="container" style="height: 460px;">
        <div class="alert alert-success mt" *ngIf="alertsuccess">
            <strong>
                Thanks for verifying your email address.
            </strong>
        </div>
        <div class="alert alert-danger mt" *ngIf="alertdanger">
            <strong>
                email address is not verified
            </strong>
        </div>
    </div>

</section>