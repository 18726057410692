import { Injectable } from '@angular/core';
import { IndividualConfig,ToastrService } from 'ngx-toastr';

//import 'style-loader!angular2-toaster/toaster.css';

@Injectable({
  providedIn: 'root'
})
export class ToasterMsgService {
  constructor(private toastr: ToastrService) {}

  individualConfig: Partial<IndividualConfig> = {
    positionClass: 'toast-top-right',
    progressBar: true,
    closeButton: true,
    onActivateTick: true,
    enableHtml: true
  };
 
 
  public showToast(type: string, title: string, body: string) {
    
    if(type == "success"){
      this.toastr.success(title,body,this.individualConfig);
    }
    else if (type == "info"){
      this.toastr.info(title,body,this.individualConfig);
    }
    else if (type == "warning"){
      this.toastr.warning(title,body,this.individualConfig);
    }
    else if (type == "error"){
      this.toastr.error(title,body,this.individualConfig);
    }
    else{
      this.toastr.show(title,body,this.individualConfig,type);
    }
    
  }
}
