import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams
} from '@angular/common/http';
import {environment} from "../../environments/environment";
import { Observable } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  _site = environment.Site;
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      var offset = new Date().getTimezoneOffset();
      var params = new HttpParams();
      params = params.append('site', this._site); 
      params = params.append('offset', offset.toString()); 
      req= req.clone({
        params:params
      });
       const key = 'valley_of_rockville_centre_token';
       const myItem = localStorage.getItem(key);
       if (!req.headers.has('Authorization')) {
        req = req.clone({headers: req.headers.append('Authorization', 'Bearer ' + myItem)});
      }
       req = req.clone({headers: req.headers.set('Cache-Control', 'max-age=31536000, must-revalidate')});
       return next.handle(req);
  }
}
