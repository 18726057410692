import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EagleJournalService {
  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllEagleJournal(pageIndex:any, pageSizeSelected:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/EagleJournal/GetAllEagleJournal?page=" + pageIndex + "&pageSize=" + pageSizeSelected,{responseType: 'json'});
  }

  GetAllEagleArchive(pageIndex:any, pageSizeSelected:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/EagleJournal/GetAllEagleArchive?page=" + pageIndex + "&pageSize=" + pageSizeSelected,{responseType: 'json'});
  }

  GetEagleJournal(obj:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/EagleJournal/GetEagleJournal/"+obj,{responseType: 'json'});
  }

  PostAddEagleJournal(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/EagleJournal/PostAddDEagleJournal", obj,headers);
  }

  PutUpdateEagleJournal(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/EagleJournal/PutUpdateDEagleJournal", obj,headers);
  }

  DeleteEagleJournal(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/EagleJournal/DeleteEagleJournal", options);
  }

}
