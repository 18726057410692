import { Component, OnInit } from '@angular/core';
import {UserSettingService} from '../../service/user-setting.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {
  previewHtml = null;
  constructor(
    private titleService: Title,
    private userSettingService: UserSettingService,
    private sanitizer: DomSanitizer) {
    this.titleService.setTitle('Privacy Policy | Valley Of Rockville Centre');
    this.userSettingService.GetPolicy().subscribe(
      res => {
        this.previewHtml = res.text;
     });

  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }

  ngOnInit(): void {
  }

}
