import { Component, OnInit,ViewChild,AfterViewInit  } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../../service/payment.service';
import { environment } from "../../../environments/environment";
import { DomSanitizer } from '@angular/platform-browser';
import {Title} from "@angular/platform-browser";
import * as moment from 'moment';
import { JpPreloadService } from '@jaspero/ng-image-preload';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  id = "";
  _type = "";

  role_id = window.localStorage.getItem('valley_of_rockville_centre_Role_id');
  userid_id = window.localStorage.getItem('valley_of_rockville_centre_UserId');
  lodge_id = window.localStorage.getItem('valley_of_rockville_centre_LodgeId');
  LodgeAccess = window.localStorage.getItem('valley_of_rockville_centre_LodgeAccess');
  _email = window.localStorage.getItem('valley_of_rockville_centre_Email');

  constructor(private paymentService:PaymentService,private router: Router) { 
   
  }

  save_Donation(obj){
    var _price = localStorage.getItem('valley_of_rockville_centre_session_Price');
    var _disc = localStorage.getItem('valley_of_rockville_centre_session_Disc');
    var _merchantFee = localStorage.getItem('valley_of_rockville_centre_session_merchantFee');

    const body = new HttpParams()
    .set('payment_third_partyid', obj.payment_intent)
    .set('custemberid', obj.customer)
    .set('stripeEmail', this._email)
    .set('UserId', this.userid_id)
    .set('MerchantFee', _merchantFee)
    .set('Price', _price.toString())
    .set('Disc', _disc.toString())
    
    this.paymentService.ChargePhilanthropyDefault(body).subscribe(
      res => {
        localStorage.removeItem('valley_of_rockville_centre_session_id');
        localStorage.removeItem('valley_of_rockville_centre_session_type');
        localStorage.removeItem('valley_of_rockville_centre_session_Price');
        localStorage.removeItem('valley_of_rockville_centre_session_Disc');
        localStorage.removeItem('valley_of_rockville_centre_session_merchantFee');
        if(res == 1){

           //this.ToasterService.showToast("success", "Donation", "Your payment was processed successfully");          
        }
        else{
          //this.ToasterService.showToast("error", "Donation", "Payment unsuccessful, please try again");          
        }
      }
    );
  }

  save_PayDue(obj){
    var _price = localStorage.getItem('valley_of_rockville_centre_session_Price');
    var _disc = localStorage.getItem('valley_of_rockville_centre_session_Disc');
    var _donationAmount = localStorage.getItem('valley_of_rockville_centre_session_DonationAmount');
    var _totaldue = localStorage.getItem('valley_of_rockville_centre_session_Due');
    var _totaldue_fee = localStorage.getItem('valley_of_rockville_centre_session_Duefee');
    // var _currentYear = localStorage.getItem('valley_of_rockville_centre_session_CurrentYear');
    // var _pastYear = localStorage.getItem('valley_of_rockville_centre_session_PastYear');
    // var _futureYear = localStorage.getItem('valley_of_rockville_centre_session_FutureYear');
    var _currentYear = "";
    var _pastYear = "";
    var _futureYear = "";
    var _merchantFee = localStorage.getItem('valley_of_rockville_centre_session_merchantFee');
    var _amount_without_marchad = localStorage.getItem('valley_of_rockville_centre_session_amount_without_merchantFee');

    // .set('CurrentYear',_currentYear)
    // .set('PastYear', _pastYear)
    // .set('FutureYear', _futureYear)

    const body = new HttpParams()
    .set('payment_third_partyid', obj.payment_intent)
    .set('custemberid', obj.customer)
    .set('stripeEmail', this._email)
    .set('UserId', this.userid_id)
    .set('Due', _totaldue.toString())
    .set('Due_Fee', _totaldue_fee.toString())
    .set('Price', _price.toString())
    .set('MerchantFee', _merchantFee)
    .set('Disc', _disc.toString())
    .set('DonationAmount', _donationAmount)
    .set('Amount', _amount_without_marchad);
    
    this.paymentService.ChargePayDuePaymentDefault(body).subscribe(
      res => {
        localStorage.removeItem('valley_of_rockville_centre_session_id');
        localStorage.removeItem('valley_of_rockville_centre_session_type');
        localStorage.removeItem('valley_of_rockville_centre_session_Price');
        localStorage.removeItem('valley_of_rockville_centre_session_Disc');
        localStorage.removeItem('valley_of_rockville_centre_session_Due');
        localStorage.removeItem('valley_of_rockville_centre_session_Duefee');
        localStorage.removeItem('valley_of_rockville_centre_session_DonationAmount');
        // localStorage.removeItem('valley_of_rockville_centre_session_CurrentYear');
        // localStorage.removeItem('valley_of_rockville_centre_session_PastYear');
        // localStorage.removeItem('valley_of_rockville_centre_session_FutureYear');
        localStorage.removeItem('valley_of_rockville_centre_session_merchantFee');
        localStorage.removeItem('valley_of_rockville_centre_session_amount_without_merchantFee');
        if(res == 1){

           //this.ToasterService.showToast("success", "Donation", "Your payment was processed successfully");          
        }
        else{
          //this.ToasterService.showToast("error", "Donation", "Payment unsuccessful, please try again");          
        }
      }
    );
  }

  save_Store(obj){
    var _price = localStorage.getItem('valley_of_rockville_centre_session_Price');
    var _disc = localStorage.getItem('valley_of_rockville_centre_session_Disc');
    var _merchantFee = localStorage.getItem('valley_of_rockville_centre_session_merchantFee');
       
    const body = new HttpParams()
    .set('payment_third_partyid', obj.payment_intent)
    .set('custemberid', obj.customer)
    .set('stripeEmail', this._email)
    .set('UserId', this.userid_id)
    .set('Price', _price.toString())
    .set('MerchantFee', _merchantFee)
    .set('Disc', _disc)
    
    this.paymentService.ChargeCashRegisterDefault(body).subscribe(
      res => {
        localStorage.removeItem('valley_of_rockville_centre_session_id');
        localStorage.removeItem('valley_of_rockville_centre_session_type');
        localStorage.removeItem('valley_of_rockville_centre_session_Price');
        localStorage.removeItem('valley_of_rockville_centre_session_merchantFee');
        localStorage.removeItem('valley_of_rockville_centre_session_Disc');
        if(res == 1){

           //this.ToasterService.showToast("success", "Donation", "Your payment was processed successfully");          
        }
        else{
          //this.ToasterService.showToast("error", "Donation", "Payment unsuccessful, please try again");          
        }
      }
    );
  }

  save_PayforDinner(obj){
    var _price = localStorage.getItem('valley_of_rockville_centre_session_Price');
    var _disc = localStorage.getItem('valley_of_rockville_centre_session_Disc');
    var _eventid = localStorage.getItem('valley_of_rockville_centre_session_eventid');
    var _numberOfGuests = localStorage.getItem('valley_of_rockville_centre_session_numberOfGuests');
       var _merchantFee = localStorage.getItem('valley_of_rockville_centre_session_merchantFee');

    const body = new HttpParams()
    .set('payment_third_partyid', obj.payment_intent)
    .set('custemberid', obj.customer)
    .set('stripeEmail', this._email)
    .set('UserId', this.userid_id)
    .set('Price', _price.toString())
    .set('EventId', _eventid)
    .set('MerchantFee', _merchantFee)
    .set('NumberofGuests', _numberOfGuests)
    .set('Disc', _disc)
    
    this.paymentService.ChargePayForDinnerDefault(body).subscribe(
      res => {
        localStorage.removeItem('valley_of_rockville_centre_session_id');
        localStorage.removeItem('valley_of_rockville_centre_session_type');
        localStorage.removeItem('valley_of_rockville_centre_session_Price');
        localStorage.removeItem('valley_of_rockville_centre_session_eventid');
        localStorage.removeItem('valley_of_rockville_centre_session_numberOfGuests');
        localStorage.removeItem('valley_of_rockville_centre_session_Disc');
        localStorage.removeItem('valley_of_rockville_centre_session_merchantFee');
        if(res == 1){

           //this.ToasterService.showToast("success", "Donation", "Your payment was processed successfully");          
        }
        else{
          //this.ToasterService.showToast("error", "Donation", "Payment unsuccessful, please try again");          
        }
      }
    );
  }

  getPayment(sid){
    var that = this;
    var _type = localStorage.getItem('valley_of_rockville_centre_session_type');
    this.paymentService.GetCheckoutSession(sid).subscribe(
      datares => {
        if(_type == "Donation"){
          that.save_Donation(datares);
        }
        else if(_type == "PayDue"){
          that.save_PayDue(datares);
        }
        else if(_type == "Store"){
          that.save_Store(datares);
        }
        else if(_type == "PayforDinner"){
          that.save_PayforDinner(datares);
        }
        
      });
  }

  ngOnInit(): void {
    if (localStorage.getItem('valley_of_rockville_centre_session_id')) {
        this.id = localStorage.getItem('valley_of_rockville_centre_session_id');  
        this.getPayment(this.id);
    }
    else{
      //this.router.navigate(['/']);
    }
  }

}
