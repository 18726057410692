import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProfileService } from '../../service/profile.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchtext = null;
  data = [];
  constructor(private router: Router,private profileService: ProfileService,public activeModal: NgbActiveModal) { }

  image_blank(imagename){
    let imagereturn = null;
    
    if (imagename == null || imagename == "null" || imagename == ""){
      imagereturn = "/assets/images/lec-01.jpg";
    }
    else{
      let result = imagename.includes("image-not-available-big.png");
      let result_null = imagename.includes("null");
      if(result == true || result_null == true){
        imagereturn = "/assets/images/lec-01.jpg";
      }
      else{
        imagereturn = imagename;
      }
    }

    return imagereturn;
  }
  
  selectedItem(obj){
    // tslint:disable-next-line:variable-name
    let _type = '';
    let text = '';
    if (obj != null){
      $('.searchIcon').click();
      if (obj.type === 'user'){
        _type = 'Brother';
        text = obj.name;
        this.searchtext = null;
        this.ProfileView(obj.value);
        this.activeModal.close();
      }
      else if (obj.type === 'event'){
        _type = 'Event';
        text = obj.name;

        this.EventView(obj.value);
        this.activeModal.close();
      }
    }
  }

  ProfileView(UserId){
    localStorage.setItem('valley_of_rockville_centre_ProfileSeach', '1');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/profiles/detail/' + UserId]);
    });
  }

  EventView(EventId){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/events/detail/' + EventId]);
    });
  }

  isObject(val) {
    return (typeof val === 'object');
  }


  resultFormat(value: any) {
    if (value === 'user'){
      return "Brother";
    }
    else if (value === 'business'){
      return "Business";
    }
    else if (value === 'event'){
      return "Event";
    }
  }

  search = (_text) => {
    if(_text != null){
      if(_text != ""){
        _text = _text.toLowerCase();
        this.profileService.HomeSearch(_text).subscribe(
          datares => {
             this.data = datares;
        });
      }
      else{
        this.data = [];
      }
      
    }
    else{
      this.data = [];
    }
    
   
  }


  ngOnInit(): void {
  }

}
