import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetCheckDatabase(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Database/GetCheckDatabase/"+id,{responseType: 'json'});
  }
  
  GetMember(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Memberlistid/GetMemberCheck/"+id,{responseType: 'json'});
  }

  GetMemberlistfetch(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Memberlistid/GetMemberlistfetch/"+id,{responseType: 'json'});
  }

  GetMemberlist(id:any) : Observable<any> {
    return this.http.get(this.baseUrl+"api/Memberlistid/GetMemberlist",{responseType: 'json'});
  }

  PostAddMember(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Memberlistid/PostAddMemberlist", obj,headers);
  }

  PutUpdateMember(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.put(this.baseUrl+"api/Memberlistid/PutUpdateMemberlist", obj,headers);
  }

  DeleteMember(obj: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders,
      body: obj
    };
    return this.http.delete(this.baseUrl+"api/Memberlistid/DeleteMemberlist", options);
  }
}
