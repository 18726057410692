<div class="main_wrapper index" id="Div1">

    <section class="application_form">
        <div class="container">

            <h3 class="row heading padding-10">
                <span class="float_left">Privacy Policy</span>
            </h3>
            <div class="summary"  [innerHTML]="transform(previewHtml)">

            </div>
        </div>
    </section>
</div>