import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PushnotificationService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json",
      'Authorization': 'key=AAAAhAm9_QM:APA91bFc9qfLXX0VYIL5fkZf-HjoqkJrH1Z6nFxLOcjx3n0A8JoIUUaQTLDxewN-GQRerWhvzHU_0SfwrE8WCM87h7P2pz3-KdUkXX1fUjBiLAXXp4a-9bkt0vPxdh5ylgFcyEJU174_',
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  AddTopicAll(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post("https://iid.googleapis.com/iid/v1/"+obj+"/rel/topics/all", {},headers);
  }

}
