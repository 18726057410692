
<div class="main_wrapper index">
    <section class="application_form">
        <div class="container">
            <h3 class="row heading padding-10">
                <span class="float_left">About Us</span>
            </h3>
            <p class="summary">
               What is the Scottish Rite?
            </p>
            <p class="summary">
                The Scottish Rite, often referred to as 32° Freemasonry, is an appendant body  of Freemasonry a Master Mason may join to journy deeper into the teachings of the craft.
            </p>
            <br>
            <p class="summary">
                Our Values
            </p>                    
            <p class="summary">
                32° Scottish Rite Masonry expands upon the fundamental principles of Freemasonry, exploring Masonic teachings more deeply. Building upon the ethical and philosophical teachings of the blue lodge, Scottish Rite reveals a wealth of knowledge about Masonry not found in the other degrees. It seeks to enrich the philosophy of the Symbolic Lodge in order to help good men become even better.
            </p>

            <p class="summary">
                A member of the Scottish Rite seeks to: 
            </p>
            <p class="summary span12">
                <b>•</b>	Aid mankind’s search for identity and destiny in God’s universe
            </p>
            <p class="summary span12">
                <b>•</b>	Produce wiser men in a wiser world, happier men in a happier world, and therefore better men in a better world
            </p>
            <p class="summary span12">
                <b>•</b>	Promote the dignity of every person and the humanity in all activities
             </p>
             <p class="summary">
                Our core values are:
            </p>
             <p class="summary span12">
                <b>•</b>	Reverence for God
            </p>
            <p class="summary span12">
                <b>•</b>	Devotion to Country
            </p>
            <p class="summary span12">
                <b>•</b>	Integrity
             </p>
             <p class="summary span12">
                <b>•</b>	Justice
             </p>
             <p class="summary span12">
                <b>•</b>	Toleration
            </p>
            <p class="summary span12">
                <b>•</b>	Service
            </p>
              
             <p class="summary">
                Our Vision
            </p>
            <p class="summary">
            We will strive to be a fraternity that fulfills our Masonic obligation to care for our members.
            32° Freemasonry seeks to strengthen the community and believes that each man should act in civil life according to his individual judgment and the dictates of his conscience.
            </p>
            <br>
            <p class="summary">
            32° Freemasonry
            </p>
            <p class="summary">
            Scottish Rite Freemasonry is a way for a Mason to build upon the ethical teachings and philosophy offered in the craft lodge (also known as Blue Lodge), which consists of the first three degrees of Freemasonry: Entered Apprentice, Fellowcraft, and Master Mason. The Scottish Rite continues these degrees, from 4° to 32°. A Scottish Rite Freemason may attain the 33°, which is an honor bestowed for outstanding service to the Brotherhood. 
            </p>
           <p class="summary">
            THE SYSTEM OF DEGREES:
           </p> 
             <p class="summary">
                 Each Valley has up to four Scottish Rite bodies, and each body confers a set of degrees. In the Northern Masonic Jurisdiction, the bodies are the:
             </p>
            <p class="summary span12">
                <b>•</b>	Lodge of Perfection (4° - 14°)
            </p>
             <p class="summary span12">
                <b>•</b>	Council of Princes of Jerusalem (15° - 16°)
            </p>
             <p class="summary span12">
                <b>•</b>	Chapter of Rose Croix (17° - 18°)
            </p>
             <p class="summary span12">
                <b>•</b>	Consistory (19° - 32°)
            </p>
        </div>
    </section>
</div>



