import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ApproverService {

  _getHeaders() {
    // let headers = new Headers();    
    // headers.append('Content-Type', 'x-www-form-urlencoded');
    // return headers;

    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetAllApprover() : Observable<any> {
    return this.http.get(this.baseUrl+"api/ApproverItem/GetAllApprover",{responseType: 'json'});
  }

  GetAllApproverLecture() : Observable<any> {
    return this.http.get(this.baseUrl+"api/ApproverItem/GetAllApproverLecture",{responseType: 'json'});
  }


  PostAddDesignation(obj: any): Observable<any> {
     return this.http.post(this.baseUrl+"api/ApproverItem/PostApprove", obj);
  }

  PostApproveLecture(obj: any): Observable<any> {
    return this.http.post(this.baseUrl+"api/ApproverItem/PostApproveLecture", obj);
  }
}
