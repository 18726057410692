import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }

  constructor(private http: HttpClient,public afAuth: AngularFireAuth) { }
  baseUrl: string = environment.ServerUrl;

  token(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    const body = new HttpParams()
    .set('username', obj.Email)
    .set('password', obj.Password)
    .set('token', obj.FirebaseUserId);

    return this.http.post(this.baseUrl+"api/token?offset=" + offset, body,headers);
  }

  doForgotPassword(email){
    return this.afAuth.sendPasswordResetEmail(email);
  }

  doCurrentUser(){
    var user = this.afAuth.currentUser;
   
  }

  doVerifyPassword(code){
    return this.afAuth.verifyPasswordResetCode(code);
  }

  doChangePassword(code,password){
    return this.afAuth.confirmPasswordReset(code,password);
  }

  FireaseBaseAuthRegister(email, password){
   return this.afAuth.createUserWithEmailAndPassword(email,password);
  }

  FireaseBaseAuthSignIn(email, password){
    return this.afAuth.signInWithEmailAndPassword(email,password);
  }

  PostEmailVerify(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/SignUp/PostEmailVerify", obj,headers);
  }

  PostForgetPassword(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/ForgetPassword/PostForgetPassword", obj,headers);
  }

  PostChangePassword(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/ForgetPassword/PostChangePassword", obj,headers);
  }

  PostChangeTempPassword(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/SignUp/PostChangesPassword", obj,headers);
  }

  PostCheckLink(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/ForgetPassword/PostCheckLink", obj,headers);
  }

  PostUserSignup(obj: any): Observable<any> {
    let headers = this._getHeaders();
    var offset = new Date().getTimezoneOffset();
    return this.http.post(this.baseUrl+"api/SignUp/PostUserSignup?offset=" + offset, obj,headers);
  }

  DeleteUser(obj: any): Observable<any> {  
    let headers = this._getHeaders();
    return this.http.delete(this.baseUrl+"api/User/DeleteUserPerm/"+ obj,headers);
  }


  PostSubmitContactData(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/ContactUs/PostSubmitContactData", obj,headers);
  }
}


// export class FirebaseErrors {
//   static Parse(errorCode: string): string {

//     let message: string;

//     switch (errorCode) {
//       case 'auth/wrong-password':
//         message = 'Invalid login credentials.';
//         break;
//       case 'auth/network-request-failed':
//         message = 'Please check your internet connection';
//         break;
//       case 'auth/too-many-requests':
//         message =
//           'We have detected too many requests from your device. Take a break please!';
//         break;
//       case 'auth/user-disabled':
//         message =
//           'Your account has been disabled or deleted. Please contact the system administrator.';
//         break;
//       case 'auth/requires-recent-login':
//         message = 'Please login again and try again!';
//         break;
//       case 'auth/email-already-exists':
//         message = 'Email address is already in use by an existing user.';
//         break;
//       case 'auth/user-not-found':
//         message =
//           'We could not find user account associated with the email address or phone number.';
//         break;
//       case 'auth/phone-number-already-exists':
//         message = 'The phone number is already in use by an existing user.';
//         break;
//       case 'auth/invalid-phone-number':
//         message = 'The phone number is not a valid phone number!';
//         break;
//       case 'auth/invalid-email  ':
//         message = 'The email address is not a valid email address!';
//         break;
//       case 'auth/cannot-delete-own-user-account':
//         message = 'You cannot delete your own user account.';
//         break;
//        default:
//         message = 'Oops! Something went wrong. Try again later.';
//         break;
//     }

//     return message;
//   }
// }