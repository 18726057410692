import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { LoaderService } from '../../service/loader.service';

@Component({
  selector: 'app-my-loader',
  templateUrl: './my-loader.component.html',
  styleUrls: ['./my-loader.component.css']
})
export class MyLoaderComponent implements AfterViewInit  {

  loading = false;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      this.cdr.detectChanges();
    });
  }

}
