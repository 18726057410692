import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams  } from '@angular/common/http';
import {Observable} from "rxjs/index";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DegreeService {

  _getHeaders() {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded',
      "Accept": "application/json"
    });
    let options = {
      headers: httpHeaders
    };

    return options
  }
  constructor(private http: HttpClient) { }
  baseUrl: string = environment.ServerUrl;

  GetSingleDegree(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Degree/GetSingleDegree/"+ obj,headers);
  }

  GetDegreeVideolist(obj: any): Observable<any> {
    var offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Degree/GetUserDegreelist/"+ obj+"?name="+offset,headers);
  }

  GetUserDegree(obj: any): Observable<any> {
    var offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Degree/GetUserDegree/"+ obj+"?name="+offset,headers);
  }

  GeDegreeVideo(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Degree/GeDegreeVideo/"+ obj,headers);
  }

  GetDegreeWatch(obj: any,_userid): Observable<any> {
    let headers = this._getHeaders();
    return this.http.get(this.baseUrl+"api/Degree/GetDegreeWatch/"+ obj + "?userid="+_userid,headers);
  }

  PostDegreeWatch(obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Degree/PostDegreeWatch", obj,headers);
  }

  PostAddComment(degreeid: any, obj: any): Observable<any> {
    let headers = this._getHeaders();
    return this.http.post(this.baseUrl+"api/Degree/PostAddComment/"+degreeid, obj,headers);
  }

  DeleteComment(degreeId: any,noteId: any): Observable<any> {
    let headers = this._getHeaders();  
    return this.http.delete(this.baseUrl+"api/Degree/DeleteComment/"+ degreeId + "?noteid="+noteId,headers);
  }

}
