<div class="middle galleryPage">
  <div class="innerBanner mb-0">
    <div class="container">
      <div class="imgBlock">
        <h2>Dues Payment Plan</h2>
      </div>
    </div>
  </div>
  <br/><br/>
  <div class="container">
    <div class="philanthropy-wrap">
      <div class="row col-md-12">
        <div class="col-md-8 col-sm-8">
          <div class="clearfix">
            <div *ngIf="!active" class="row form-group clearfix">
              <label class="labelpadding center-display"><h6 class="mt-2">SPLIT DUES INTO MONTHLY PAYMENTS</h6> </label>

            </div>
            <div *ngIf="active" class="row form-group clearfix">
              <label class="labelpadding center-display"><h5 class="mt-2">{{data.subscription.product_name.toUpperCase()}}</h5> </label>
            </div>

          </div>
        </div>
        <div class="col-md-4 col-sm-4 mt-2">
          <h6 class="text-right">DUES AMOUNT FOR {{currentYear}} IS <b>${{duesAmount}}.00</b></h6>
          <br/>
        </div>
      </div>
      <div class="or-divider clearfix pt-0 pb-0">
        <div class="divider-title"></div>
      </div>
      <div>
        <div *ngIf="active">
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="!paymentFailure" class="alert alert-success card" role="alert">
                <div class="card-body">
                  <h3 class="alert-heading">{{data.subscription?.product_name}}</h3>
                  <p>$41.79 per month from <b>{{data.subscription.start}}</b> to <b>{{data.subscription.end}}</b></p>
                  <h6>Your subscription is active <i class="fa fa-check-circle" style="font-size: 21px;"></i></h6>
                  <a style="font-weight: 600; padding-left: 0;" href="#" class="btn btn-link" (click)="openSubscriptionManagementUrl()">Edit Subscription</a>
                </div>
              </div>
              <div *ngIf="paymentFailure" class="alert alert-danger card" role="alert">
                <div class="card-body">
                  <h3 class="alert-heading">{{data.subscription?.product_name}}</h3>
                  <p>$41.79 per month from <b>{{data.subscription.start}}</b> to <b>{{data.subscription.end}}</b></p>
                  <h6>Your subscription has been suspended  <i class="fa fa-exclamation-circle" style="font-size: 21px;"></i></h6>
                  <b>There was an issue processing a payment on {{latestPaymentFailureDate}}.  Please reach out to the Valley for further instructions.</b>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="container mt-3">-->
<!--          <h6>Manage Subscription</h6>-->
<!--          <button class="btn btn-primary" (click)="openSubscriptionManagementUrl()">Edit Subscription</button>-->
<!--        </div>-->

        <div class="container" *ngIf="!active">
          <div class="philanthropy-wrap">
            <div class="donattype inline-selectform clearfix">
              <h4>Choose a dues payment plan:</h4>
              <br/>
              <div class="search-dropdown galleryvalley" *ngFor="let plan of plans">
                <div class="input-group-btn" style="padding-right: 5px; padding-bottom: 0;">
                  <button id="{{plan.id}}" class="dropdown-btn text-dark donation_desc" (click)="selectPlan(plan)" type="button">
                    ${{plan.total}} Plan
                  </button>
                </div>
              </div>
            </div>
            <div class="fine-print" *ngIf="oneYear">
              <label class="labelpadding"><h6 class="mt-2">Split $160 across <b>four</b> monthly payments of $41.79</h6> </label>
              <br />
              <span class="plan-span">Payments will be automatically charged to your debit / credit card each month.</span>
              <app-timeline></app-timeline>
            </div>
            <div *ngIf="twoYears">
              <label class="labelpadding"><h6 class="mt-2">Split $320 across <b>eight</b> monthly payments of $41.79</h6></label>
              <br />
              <span class="plan-span">Payments will be automatically charged to your debit / credit card each month.</span>
              <app-timeline numberOfMonths="8"></app-timeline>
            </div>
            <div class="fine-print-fee" *ngIf="oneYear || twoYears"><span class="fine-print-star">*</span><i>Merchant fee is included in the price.</i></div>
          </div>

        <app-subscription-button [siteId]="siteId" [userId]="userId" [emailAddress]="emailAddress" [priceId]="plan1.priceId" [noMonths]="plan1.duration" [productName]="plan1.name" *ngIf="oneYear"></app-subscription-button>
        <app-subscription-button [siteId]="siteId" [userId]="userId" [emailAddress]="emailAddress" [priceId]="plan2.priceId" [noMonths]="plan2.duration" [productName]="plan2.name" *ngIf="twoYears"></app-subscription-button>

        </div>
        </div>
    </div>
  </div>
</div>
